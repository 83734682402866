import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { S1Modal, IS1Modal, S1ButtonType, S1ModalSizes } from '@app/s1';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IAsset } from '@app/shared/models/models';
import { SettingsService } from '@app/core/settings/settings.service';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { ProfileTypeName } from '@app/shared/models/profile';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'mdm-asset-modal',
  templateUrl: './asset-modal.component.html',
  styleUrls: ['./asset-modal.component.scss']
})
export class AssetModalComponent implements OnInit, IS1Modal {

  @Input() operativeSystemMode: boolean = false;
  @Input() includeOs: boolean = false;

  @Output() assetAdded = new EventEmitter<IAsset>();
  @Output() assetUpdated = new EventEmitter<IAsset>();

  @ViewChild('modal') modal: S1Modal;

  //@ViewChild('richTextRef') richTextRef: ElementRef;
  placeholders: [];
  osAssetType: any;
  s1ButtonType = S1ButtonType;
  asset: IAsset;
  assetForm: UntypedFormGroup;
  hasError: Boolean = false;

  public uploader: FileUploader;
  assetSelected: any;
  exeecedSizeError = false;
  maxSize = 100*1024*1024;
  maxSizeOS = 100*1024*1024; //change to 2024

  constructor(private formBuilder: UntypedFormBuilder, private profiles: ProfilesService, private settings: SettingsService) {
    this.assetForm = this.formBuilder.group({
      type: null,
      assetType: null,
      description: null,
      name: null,
      source: null,
      localFile: null,
      target: null,
      placeholder: false,
      executeWith: null,
      packageName: null,
      flgExtract: null,
      flgEsecViaStringa: false,
      stringaEsec: ''
    });

    this.uploader = new FileUploader({ url: null });

    this.assetForm.controls.localFile.valueChanges.subscribe(val => {

      if((val && this.asset.localFile) || (!val && !this.asset.localFile)) {
        this.assetForm.controls.name.patchValue(this.asset.name);
        this.assetForm.controls.source.patchValue(this.asset.source);
      } else {
        this.assetForm.controls.name.patchValue(null);
        this.assetForm.controls.source.patchValue(null);
      }
      
      this.uploader.queue = [];
      this.assetSelected = null;
      this.exeecedSizeError = false;

      this.assetForm.controls.flgExtract.patchValue(this.setFlgExtract(this.asset?.name));
    });

    this.assetForm.controls.name.valueChanges.subscribe(val => {
      this.setFlgExtract(val);
    });

    this.assetForm.controls.type.valueChanges.subscribe(val => {
      //trigger source.valueChanges
      this.assetForm.controls.name.patchValue(this.assetForm.controls.name.value);
      this.assetForm.controls.localFile.patchValue(this.assetForm.controls.localFile.value);
    });
  }

  ngOnInit(): void {
    this.assetForm.controls.assetType.valueChanges.subscribe(
      _ => this.hasError = false
    );
    this.assetForm.controls.description.valueChanges.subscribe(
      _ => this.hasError = false
    );
    this.assetForm.controls.name.valueChanges.subscribe(
      _ => this.hasError = false
    );
    this.assetForm.controls.source.valueChanges.subscribe(
      _ => this.hasError = false
    );
    this.assetForm.controls.target.valueChanges.subscribe(
      _ => this.hasError = false
    );
    this.assetForm.controls.executeWith.valueChanges.subscribe(
      _ => this.hasError = false
    );
    this.assetForm.controls.packageName.valueChanges.subscribe(
      _ => this.hasError = false
    );
    if(this.operativeSystemMode) {
      this.getOsAssetType();
    }
  }

  open(asset?: IAsset) {
    this.hasError = false;
    this.exeecedSizeError = false;
    this.assetSelected = null;
    this.asset =  {...asset};
    console.warn(asset)
    

    this.assetForm.patchValue({
      type: asset?.type,
      assetType: asset?.idAssetType,
      description: this.asset?.description,
      name: this.asset?.name,
      source: this.asset?.source,
      localFile: this.asset?.localFile,
      target: this.asset?.target,
      placeholder: this.asset?.placeholder,
      executeWith: this.asset?.executeWith,
      packageName: this.asset?.packageName,
      flgExtract: this.asset?.flgExtract,
      flgEsecViaStringa: this.asset?.flgEsecViaStringa,
      stringaEsec: this.asset?.stringaEsec
    });

    console.log(this.assetForm.controls)

    this.modal.open(S1ModalSizes.LG);
  }

  private getOsAssetType() {
    this.profiles.getAssestTypes(this.operativeSystemMode).subscribe( typesList => {
      this.osAssetType = typesList.find( type => {
        return type.code == ProfileTypeName.os;
      });
    });
  }

  setFlgExtract(val) {
    if(this.isType('File')) {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(val)[1];
      if(ext=='zip') {
        if(this.assetForm.controls.flgExtract.value != null) {
          this.assetForm.controls.flgExtract.patchValue(this.assetForm.controls.flgExtract.value);
        } else {
          this.assetForm.controls.flgExtract.patchValue(false);
        }
      } else {
        this.assetForm.controls.flgExtract.patchValue(null);
      }
    } else {
      this.assetForm.controls.flgExtract.patchValue(null);
    }
    return this.assetForm.controls.flgExtract.value
  }

  typeChange(type) {
    this.assetForm.patchValue({
      type: type ? type.label: null,
      assetType: type ? type.code: null
    });

    this.validateAssetSize();
  }

  executeWithChange(type) {
    this.assetForm.patchValue({
      executeWith: type ? type.code: null
    });
  }

  isType(type: string) {
    return this.assetForm.controls?.type?.value?.toUpperCase() == type.toUpperCase();
  }

  validateAssetSize() {
    if(this.assetForm.controls.type.value == 'Operating System') {
      this.exeecedSizeError = this.assetSelected?.file ? this.assetSelected.file?.size > this.maxSizeOS : false;
    } else {
      this.exeecedSizeError = this.assetSelected?.file ? this.assetSelected.file?.size > this.maxSize : false;
    }
  }

  validateForm() {
    //validazione custom per superare i limiti del componente s1
    //non è possibile controllare lo stato 'invalid' della select
    //in apertura della modale
    if(!this.operativeSystemMode && !this.assetForm.controls.assetType.value){
      this.setControlError(this.assetForm.controls.assetType, true);
      this.hasError = true;
    }

    if(!this.assetForm.controls.description.value){
      this.setControlError(this.assetForm.controls.description, true);
      this.hasError = true;
    }
    if(!this.isFormConfigurationToolTyle()){

      if(!this.assetForm.controls.name.value){
        this.setControlError(this.assetForm.controls.name, true);
        this.hasError = true;
      }
      if(!this.assetForm.controls.source.value || (this.assetForm.controls.localFile.value && this.exeecedSizeError)){
        this.setControlError(this.assetForm.controls.source, true);
        this.hasError = true;
      }

    }
    if(this.assetForm.controls.type?.value && this.assetForm.controls.type.value.toUpperCase() == 'APP'){
      if(!this.assetForm.controls.packageName.value){
        this.setControlError(this.assetForm.controls.packageName, true);
        this.hasError = true;
      }
    }
    if(this.assetForm.controls.type?.value && this.assetForm.controls.type.value.toUpperCase() == 'FILE'){
      if(!this.assetForm.controls.target.value){
        this.setControlError(this.assetForm.controls.target, true);
        this.hasError = true;
      }
    }
    if(this.assetForm.controls.type?.value && this.assetForm.controls.type.value.toUpperCase() == 'TOOL'){
      if(!this.assetForm.controls.executeWith.value){
        this.setControlError(this.assetForm.controls.executeWith, true);
        this.hasError = true;
      }
    }
  }

  setControlError(control, error = null) {
    if(error) {
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    } else {
      control.setErrors(null)
    }
  }

  fileSelected() {
    this.assetSelected = this.uploader?.queue[this.uploader?.queue.length -1];
    this.uploader.queue = [this.assetSelected];
    this.validateAssetSize();
    this.assetForm.controls.name.patchValue(this.assetSelected.file?.name);
    this.assetForm.controls.source.patchValue(this.assetSelected.file?.name);
  }

  fileChanged(event:any):void{
    if(event.srcElement) {
      event.srcElement.value = '';
    }
  }

  close() {
    this.asset = null;
    this.modal.close();
  }

  save() {
    this.validateForm();
    if(this.hasError) {
      return false;
    }

    const idAssetType = this.operativeSystemMode ? this.osAssetType.id : this.assetForm.controls.assetType.value;
    const type = this.operativeSystemMode ? this.osAssetType.code : this.assetForm.controls.type.value;
    const description = this.assetForm.controls.description.value;
    const localFile = this.assetForm.controls.localFile.value;
    const source = this.assetForm.controls.source.value;
    const file = localFile ? this.assetSelected?.file?.rawFile : null;
    const target = this.isType('File') ? this.assetForm.controls.target.value : null;
    const placeholder = (this.isType('File') || this.isType('Tool')) ? this.assetForm.controls.placeholder.value : null;
    const executeWith = this.isType('Tool') ? this.assetForm.controls.executeWith.value : null;
    const packageName = this.isType('App') ? this.assetForm.controls.packageName.value : null;
    const name = this.assetForm.controls.name.value;
    const flgExtract = this.assetForm.controls.flgExtract.value;
    const installationOrder = this.asset.installationOrder;
    const replacer = installationOrder ? true : false;
    const idCompany = this.settings.getCompany().code;
    const flgEsecViaStringa = this.assetForm.controls.flgEsecViaStringa.value ? true : false;
    const stringaEsec = this.isFormConfigurationToolTyle() ? this.assetForm.controls.stringaEsec.value : '';

    if (this.asset?.id == null) {
      const newAsset: IAsset = {
        idAssetType: idAssetType,
        type: type,
        description: description,
        localFile: localFile,
        source: source,
        file: file,
        target: target,
        placeholder: placeholder,
        executeWith: executeWith,
        packageName: packageName,
        name: name,
        flgExtract: flgExtract,
        installationOrder: installationOrder,
        replacer: replacer,
        idCompany: idCompany,
        flgEsecViaStringa: flgEsecViaStringa,
        stringaEsec: stringaEsec
      };
      this.assetAdded.emit(newAsset);
    } else {
      this.asset.idAssetType = idAssetType;
      this.asset.type = type;
      this.asset.description = description;
      this.asset.localFile = localFile;
      this.asset.source = source;
      this.asset.file = file;
      this.asset.target = target;
      this.asset.placeholder = placeholder;
      this.asset.executeWith = executeWith;
      this.asset.packageName = packageName;
      this.asset.name = name;
      this.asset.flgExtract = flgExtract;
      this.asset.idCompany = idCompany;
      this.asset.flgEsecViaStringa = flgEsecViaStringa;
      this.asset.stringaEsec = stringaEsec;
      this.assetUpdated.emit(this.asset);
    }
    this.asset = null;
    this.modal.close()
  }
  
  changeInput(text) {
    this.showPlaceholder(text);
  }

  showPlaceholder(text) {
    this.placeholders = (text || text == '') ? this.match(text) : [];
  }

  match(text) {
    var entities = {'>': '&gt;','<':'&lt;'};
    var regex = new RegExp(Object.keys(entities).join("|"),'g');
    var converted = text.replace(regex, function(x, j) {
      return entities[x];
    });
    return converted.match(/((?:\\)*)([{]{2}(.*?)[}]{2})/g);
  }

  isFormConfigurationToolTyle(){
    return (this.isType('Tool') && this.assetForm.controls.flgEsecViaStringa.value);
  }
}