import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { IS1InputSelectItem } from '@app/s1';
import { IAssetsSearchParams } from '@app/shared/services/assets.service';
import { ProfilesService } from '@app/shared/services/profiles.service';
import { TranslateService } from '@ngx-translate/core';

export interface IS1AgentInputSelectItem extends IS1InputSelectItem{
  releaseNote?: string;
  deprecationNote?: string;
  deprecated?: boolean;
}
@Component({
  selector: 'mdm-input-agent-list-select',
  templateUrl: './input-agent-list-select.component.html',
  styleUrls: ['./input-agent-list-select.component.scss']
})
export class InputAgentListSelectComponent implements OnInit {

  @Input() idCompany: number;
  @Input() control: UntypedFormControl;
  @Input() readonly: boolean = false;
  @Input() avoidLoadOnReadOnly: boolean = false;
  @Input() bindAllObject: boolean = true;
  @Input() enabledInfo: boolean = true;
  @Input() set idBrand(newValue: number) {
    this.brandSelected = newValue != null;
    this.control.patchValue(null);
    if (!this.avoidLoadOnReadOnly || (this.avoidLoadOnReadOnly && newValue)) {
      this.getAssets(newValue);
    }
  }
  @Output() onSelectClear = new EventEmitter();
  @Output() onSelectChange = new EventEmitter();
  @Output() onLoadList = new EventEmitter<IS1InputSelectItem[]>();

  brandSelected: boolean = false;

  deprecated: boolean = false;
  agentList: IS1AgentInputSelectItem[];
  currentReleaseNote: string;
  currentDeprecationNote: string;

  constructor(
    private profilesService: ProfilesService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    if (!this.avoidLoadOnReadOnly) {
      this.getAssets();
    }
  }

  getAssets(idbrand?: number) {
    const parameters: IAssetsSearchParams = {
      idBrand: idbrand
    };

    this.profilesService.getAgentAssets(parameters).subscribe(assets => {

      this.agentList = assets.map(asset => {
        return {
          code: asset.id,
          label: asset.description,
          releaseNote: asset.releaseNote,
          deprecationNote: asset.deprecationNote,
          deprecated: asset.deprecated,
          brands: asset.brands
        };
      });

      if(this.control.value) {
        this.deprecated = this.agentList.find(asset => asset.code === this.control.value)?.deprecated;
        this.currentReleaseNote = this.agentList.find(asset => asset.code === this.control.value)?.releaseNote;
        this.currentDeprecationNote = this.agentList.find(asset => asset.code === this.control.value)?.deprecationNote;
      } 

      this.onLoadList.emit(this.agentList);
    });

  }

  cleared() {
    this.currentReleaseNote = null;
  }

  changed(event: IS1AgentInputSelectItem) {
    this.deprecated = event?.deprecated;
    this.currentReleaseNote = event?.releaseNote;
    this.currentDeprecationNote = event?.deprecationNote;
  }

}
