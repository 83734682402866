import { IS1SearchParams, IS1SimplePaginatedResult } from "@app/s1";

export interface IUser {
  id?: number,
  idUser?: number,
  username?: string,
  name: string,
  surname: string,
  email?: string,
  password?: string,
  codeRole: string,
  enabled: boolean,
  commandEnabled: boolean,
  passwordByUser: boolean,
  idCompany: number,
  deletable?: boolean,
  flgFirstLogin?: boolean
  regions?: IRegion[],
  sites?: ISite[]
  groups?: IGroup[];
  idRegion?: number,
  nameRegion?: string,
  idSite?: number,
  nameSite?: string,
  hasRemoteAgent?: boolean,
  remoteAgent?: string,
  mdm: false,
  dispensing: false,
  shop: false,
}

export interface ICompany {
  id?: number,
  code: string,
  name: string,
  localFile: boolean,
  fileUrl?: string
  imageFile?: any,
  reseller: boolean,
  flgSso?: boolean,
  idResellerCompany?: number,
  codeResellerCompany?: string,
  shop: boolean,
  dispensing: boolean,
  mdm: boolean,
  licensedUnits?: number,
  activeUnits?: number,
  placeholderHierarchy?: IPlaceholderHierarchy
}

export interface IRegion {
  id?: number,
  code: string,
  name: string,
  idCompany: number,
  nameCompany?: string,
  placeholderHierarchy?: IPlaceholderHierarchy
}

export interface ISite {
  id?: number,
  idCompany: number,
  idRegion: number,
  code: string,
  name: string,
  nameCompany?: string,
  nameRegion?: string,
  placeholderHierarchy?: IPlaceholderHierarchy
}

export interface IPlaceholder {
  id?: number;
  key?: string;
  idCompany?: number;
  nameCompany?: string;
  idRegion?: number;
  nameRegion?: string;
  idSite?: number;
  nameSite?: string;
  codUnit?: string;
  value?: string;
  deletable?: boolean;
  modified?: boolean;
}

export interface IPlaceholdersSearchParams {
  key?: string;
}

export interface IPlaceholderListEntry {
  key?: string;
  companyName?: string;
  company?: IPlaceholder[];
  region?: IPlaceholder[];
  site?: IPlaceholder[];
  unit?: IPlaceholder[];
  deleted?: number[];
}

export interface IPlaceholderHierarchy {
  company?: IPlaceholderLevel,
  region?: IPlaceholderLevel,
  site?: IPlaceholderLevel
}

export interface IPlaceholderLevel {
  id?: number,
  placeholders: { key: string; value: string; deletable?: boolean;}[];
}

export interface IPlaceholderCreate {
  idCompany: number;
  key: string;
}

export interface IGroup {
  id?: number,
  code: string,
  name: string,
  idCompany: number
}

export interface IBrand {
  id?: number,
  code: string,
  name: string
}

export enum BrandCode {
  zebra = "Zebra Technologies",
  datalogic = "Datalogic"
}

export interface IModel {
  id?: number,
  code: string,
  name: string,
  idBrand: number
}

export interface IUnit {
  id?: number,
  mdmId?: number,
  idAndroidEnterprise?: string,
  dispensingId?: number,
  code: string,
  idCompany: number,
  nameCompany: string,
  idRegion: number,
  nameRegion: string,
  idSite: number,
  nameSite: string,
  idGroup: number,
  nameGroup: string,
  idBrand: number,
  nameBrand: string,
  idModel: number,
  nameModel: string,
  ipaddress: string,
  btmac: string,
  wifimac: string,
  batterySerialNumber: string,
  batteryPerc: number,
  batteryHealth: number,
  appRelease: string,
  osRelease: string,
  fingerprint: string,
  status: string,
  tsLastStatus: number,
  tsLastEvent: number,
  tsLastMessage: number,
  tsLastFeedback: number,
  tsLastProfileCheck: number,
  tsLastProfileUpdate: number,
  secondsSinceLastEvent: number,
  secondsSinceLastStatus: number,
  idSettingsProfile: number,
  idSettingsProfilePending: number,
  idNetworkProfile: number,
  idNetworkProfilePending: number,
  idAssetsProfile: number,
  idAssetsProfilePending: number,
  statusSettingsProfile: number,
  statusNetworkProfile: number,
  statusAssetsProfile: number,
  profileStatus: string,
  profileForced: boolean,
  tsDisabling?: number,
  disablingReason: string,
  lastTsDisabling: number,
  lastTsEnabling?: number,
  lastDisablingReason: string,
  lastDisablingFailure?: boolean,
  idUnitProfile?: number,
  countFall: number,
  failure: boolean,
  tsEnabling?: number,
  unitDisablements: IUnitDisablement[],
  deprecated?: boolean
}
export interface IUnitDisablement {
  id: number,
  idUnit: number,
  failure: boolean,
  codUnit: string,
  username: string,
  disablingReason: string,
  tsCreated: number,
  tsEnabling?: number
}

export interface ITimeline {
  id: number,
  appRelease: string,
  osRelease: string,
  sdkVersion: string,
  wifiMac: string,
  tsEvent: number,
  dateEvent?: string,
  timeEvent?: string,
  tsInsertGcp: number,
  myFirstTimeWithSmartilio: boolean,
  codEvent: string,
  batteryperc: number,
  ipaddress: string,
  wifiSignal: number,
  tsStatus: number,
  batteryHealth: number,
  timelineEventType: string,
  command: string,
  success: string,
  executeNow: string,
  tsCmdReceived: number,
  tsStartExecution: number,
  tsEndExecution: number,
  message: string,
  stacktrace: string
  mode: string,
  body: string,
  source: string,
  tsCreated: number
}

export interface IAsset {
  id?: number,
  type?: string,
  idAssetType?: number,
  versionAsset?: string,
  description: string,
  source?: string,
  localFile?: boolean,
  file?: any,
  target?: string,
  placeholder?: boolean,
  executeWith?: string,
  packageName?: string,
  releaseNote?: string,
  name?: string,
  flgExtract?: boolean;
  children?: IAsset[],
  hasChildren?: boolean,
  idCompany?: number,
  isRemovable?: boolean,
  replacer?: boolean,
  confirmRemotion?: boolean,
  installationOrder?: number,
  groupedRegionId?: number,
  groupedSiteId?: number
  groupedBrandId?: number,
  groupedGroupId?: number,
  groupedModelId?: number,
  brands?: IBrand[],
  flgEsecViaStringa?: boolean,
  stringaEsec?: string,
  deprecated?: boolean,
  deprecationNote?: string 
}

export enum AssetTypeCode {
  file = "FILE",
  app = "APP",
  tool = "TOOL",
  os = "OS",
  agent = "AGENT"
}

export interface IpAssignment {
  id?: number,
  idCompany: number,
  idGroup?: number,
  idRegion?: number,
  idSite?: number,
  codUnit?: string,
  ipAddress?: string,
  ipAddressFrom?: string,
  ipAddressTo?: string,
  subnetMask?: string,
  subnet?: string,
  description?: string;
  dns1?: string;
  dns2?: string;
  ipGateway?: string;
}

//Dispensing

export enum UnitType {
  UNIT = "MOBILE",
  KIOSK = "ENTRANCE_UNIT"
}
export interface IDispensingUnitsSearchParams extends IS1SearchParams {
  codUnit: string,
  idCompany: number,
  idRegion: number,
  idSite: number,
  idGroup?: number,
  codWall: number,
  statusLevel: string,
  unitType: string
}

export interface IDispensingUnitsPaginated extends IS1SimplePaginatedResult {
  units: IDispensingUnit[]
}
export interface IDispensingUnit {
  appRelease?: string,
  id?: number,
  dispensingId?: number,
  code: string,
  idCompany: number,
  nameCompany: string,
  idRegion: number,
  nameRegion: string,
  idSite: number,
  nameSite: string,
  idGroup: number,
  nameGroup: string,
  idWall: number,
	codWall: string,
	descriptionWall: string,
  ipaddress: string,
  osrelease: string,
  model: string,
  wifimac: string,
  row: number,
  col: number,
  score: number,
	userCode: string,
  unitStatus: string,
  btmac: string,
  appVersion: string,
  batteryPerc: number,
  batterySerialNumber: string,
  tsLastStatus: number,
  tsLastEvent: number,
  lastTimeEvent: number,
  type: string,
	countExpenseLastWeek: number,
	countMissingPickUp: number,
	lastTimeStatus: number,
  batteryLevel: string,
  lastStatusLevel: string,
  globalStatus: string,
  statusLevel: string,
  isExcluded: boolean,
  nameBrand?: string,
  nameModel?: string
}

export interface IWall {
  id: number;
  code: string;
  description: string;
  idCompany?: number,
  nameCompany?: string,
  idRegion?: number,
  nameRegion?: string,
  idSite?: number,
  nameSite?: string,
  countTotalExpense?: number;
  idAuthorizationWall?: number;
  rows: number;
  cols: number;
  units?: IDispensingUnit[][];
  show?: boolean;
}
export interface ICommand{
  tipo: string,
  name:string,
  parameter:ICommandParam[]
}
export interface ICommandParam{
  code:string,
  name:string
}
export interface ICommandUnits{
  ts_feedback:string,
  dispositivo:string,
  esito:number
}
export interface ICommandList {
  id:number,
  comando: ICommand,
  ts_invio:string,
  feedback:string,
  errors:string,
  broadcast:string,
  statuscode:number,
  statusname:string,
  dispositivi:ICommandUnits[]
  isExpanded:boolean
}

export interface IBarcode {
  id: number;
  idBrand?: number;
  nameBrand?: string;
  idRegion?: number;
  nameRegion?: string;
  idSite?: number;
  nameSite?: string;
  idGroup?: number;
  nameGroup?: string;
  idAgent?: number;
  nameAgent?: string;
  description?: string;
  descriptionAgent?: string;
  stagingToken?: string;
}