import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'battery-health-icon',
  templateUrl: './battery-health-icon.component.html',
  styleUrls: ['./battery-health-icon.component.scss']
})

export class BatteryHealthIconComponent implements OnChanges {

  @Input() health: number;

  healthStyle: string;
  available: boolean;

  constructor() { }

  ngOnChanges() {
    this.available = !!this.health && this.health >= 0 && this.health <= 100
    this.healthStyle = this.getBatteryHealthStyle();
  }

  getBatteryHealthStyle() {
    if (this.available) {
      if (this.health > 90) {
        return 'fas fa-heartbeat fa-1x text-success';
      } else if (this.health <= 90 && this.health > 80) {
        return 'fas fa-heartbeat fa-1x text-warning';
      } else if (this.health <= 80) {
        return 'fas fa-heartbeat fa-1x text-danger';
      }
    } else {
      return 'fas fa-heartbeat fa-1x text-muted';
    }
  }

}
