import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType, S1Modal, IS1Modal, S1ModalSizes } from '@app/s1';
import { AeService, IApplication, IApplicationPolicy, IApplicationSearchParams } from '@app/shared/services/ae.service';

@Component({
  selector: 'mdm-application-configuration-modal',
  templateUrl: './application-configuration-modal.component.html',
  styleUrls: ['./application-configuration-modal.component.scss']
})
export class AeApplicationConfigurationModalComponent implements OnInit, IS1Modal {

  @ViewChild('modal') modal: S1Modal;
  @Output() onSaved = new EventEmitter<any>();
  s1ButtonType = S1ButtonType;
  configurationForm: UntypedFormGroup;
  installTypeOptions = [];
  autoUpdateModeOptions = [];
  permissionPolicyOptions = [];
  
  application: IApplication;
  applicationPolicy: IApplicationPolicy;
  applicationPolicyIndex: number;
  
  packageNamesAlreadyUsedInPolicy = [];
  packageNameAlreadyUsed = false;
  packageNameNotFound = false;
  
  managedConfigurationsFormArray: UntypedFormArray;
  managedConfigurationsArrayOptions = [];
  
  applicationPermissionsFormArray: UntypedFormArray;
  applicationPermissionsOptions = [];
  
  
  constructor(private formBuilder: UntypedFormBuilder, private aeService: AeService, private settings: SettingsService) {
    this.configurationForm = this.formBuilder.group({
      packageName: [null],
      installType: [null, Validators.required],
      autoUpdateMode: [null],
      minimunVersionCode: [null],
      defaultPermissionPolicy: [null],
      newPermissionKey: [null],
      newPermissionPolicy: [null]
    });
    this.configurationForm.get('packageName').markAsTouched();
    this.configurationForm.get('installType').markAsTouched();
    this.installTypeOptions = [
      "INSTALL_TYPE_UNSPECIFIED",
      "PREINSTALLED",
      "FORCE_INSTALLED",
      "BLOCKED",
      "AVAILABLE",
      "REQUIRED_FOR_SETUP",
      "KIOSK"
    ]
    this.autoUpdateModeOptions = [
      {"code": "AUTO_UPDATE_MODE_UNSPECIFIED", "label": "AUTO_UPDATE_MODE_UNSPECIFIED"},
      {"code": "AUTO_UPDATE_DEFAULT", "label": "AUTO_UPDATE_DEFAULT"},
      {"code": "AUTO_UPDATE_POSTPONED", "label": "AUTO_UPDATE_POSTPONED"},
      {"code": "AUTO_UPDATE_HIGH_PRIORITY", "label": "AUTO_UPDATE_HIGH_PRIORITY"}
    ];
    this.permissionPolicyOptions = [
      {"code": "PERMISSION_POLICY_UNSPECIFIED", "label": "PERMISSION_POLICY_UNSPECIFIED"},
      {"code": "PROMPT", "label": "PROMPT"},
      {"code": "GRANT", "label": "GRANT"},
      {"code": "DENY", "label": "DENY"}
    ];
  }

  ngOnInit(): void {
    this.configurationForm.controls.packageName.valueChanges.subscribe( value => {
        if(value) {
          this.packageNameAlreadyUsed = false;
          this.packageNameNotFound = false;
        }
      }
    );
  }

  setControlError(control, error = null) {
    if(error) {
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    } else {
      control.setErrors(null);
    }
  }
  
  open(packageNameAlreadyUsedInPolicy: string[], applicationPolicy: any, applicationPolicyIndex: number) {
    this.packageNamesAlreadyUsedInPolicy = packageNameAlreadyUsedInPolicy;
    this.applicationPolicy = applicationPolicy;
    this.applicationPolicyIndex = applicationPolicyIndex;
    this.application = null;
    this.packageNameAlreadyUsed = false;
    this.packageNameNotFound = false;

    //reset
    this.configurationForm.patchValue({
      packageName: null,
      installType: null,
      autoUpdateMode: null,
      minimunVersionCode: null,
      defaultPermissionPolicy: null,
      newPermissionKey: null,
      newPermissionPolicy: null
    });

    this.setApplicationPolicyForm(applicationPolicy?.packageName);
    this.modal.open(S1ModalSizes.LG);
  }

  loadApplication(packageName: any) {
    this.packageNameNotFound = false;
    if(this.packageNamesAlreadyUsedInPolicy.includes(packageName)) {
      this.setControlError(this.configurationForm.controls.packageName, true);
      this.packageNameAlreadyUsed = true;
      this.setApplicationPolicyForm(packageName);
    } else {
      this.packageNameAlreadyUsed = false;
      this.setApplicationPolicyForm(packageName);
    }
  }

  setApplicationPolicyForm(packageName: string) {
    
    this.managedConfigurationsFormArray = this.formBuilder.array([]);
    this.managedConfigurationsArrayOptions = [];
    this.applicationPermissionsFormArray =  this.formBuilder.array([]);

    if(packageName && !this.packageNameAlreadyUsed && !this.packageNameNotFound) {
      //nuova configurazione, applicationPolicy null, filtro su packageNameAlreadyUsedInPolicy
      //modifica configurazione, packageName non modificabile
      //get struttura da packageName
      let parameters: IApplicationSearchParams = {
        packageName: packageName,
        idCompany: this.settings.getCompany().code,
      }
      this.aeService.getApplication(parameters).subscribe(application => {
        this.application = application;
        if(!this.application) {
          this.setControlError(this.configurationForm.controls.packageName, true);
          this.packageNameNotFound = true;
        } else {
          this.application.packageName = packageName;
          this.packageNameNotFound = false;
          
          //init form permissions
          this.applicationPermissionsOptions = this.application.permissions.map(applicationPermission => {
            return applicationPermission.permissionId;
          });
          for (let i = 0; i < this.applicationPolicy?.permissions.length; i++) {
            this.applicationPermissionsFormArray.push(this.formBuilder.control(this.applicationPolicy?.permissions[i].policy));
          }

          //init form managedConfigurations values
          let managedConfigurationsStringValues = Array(this.application.managedProperties.length).fill('');
          let managedConfigurationsArrayValues = Array(this.application.managedProperties.length).fill([]);
          this.managedConfigurationsArrayOptions = Array(this.application.managedProperties.length).fill([]);
          if(this.applicationPolicy?.managedConfiguration) {

            managedConfigurationsStringValues = this.application.managedProperties.map(applicationProperty => {
              if(['STRING','HIDDEN','INTEGER','BOOL','CHOICE'].includes(applicationProperty.type)) {
                let managedConfiguration = this.applicationPolicy.managedConfiguration.find(managedConfiguration => managedConfiguration.key == applicationProperty.key);
                return managedConfiguration?.value;
              } else {
                return null;
              }
            });
            managedConfigurationsArrayValues = this.application.managedProperties.map(applicationProperty => {
              if(['MULTISELECT'].includes(applicationProperty.type)) {
                let managedConfiguration = this.applicationPolicy.managedConfiguration.find(managedConfiguration => managedConfiguration.key == applicationProperty.key);
                return managedConfiguration?.value;
              } else {
                return [];
              }
            });
          }
          
          //setup options
          this.managedConfigurationsArrayOptions = this.application.managedProperties.map(applicationProperty => {
            if(['CHOICE'].includes(applicationProperty.type)) {
              return applicationProperty.entries.map(e => e.code);
            } else if(['MULTISELECT'].includes(applicationProperty.type)) {
              return applicationProperty.entries;
            } else {
              return [];
            }
          });

          for (let i = 0; i < this.application.managedProperties?.length; i++) {
            if(['STRING','HIDDEN','INTEGER','BOOL','CHOICE'].includes(this.application.managedProperties[i].type)) {
              this.managedConfigurationsFormArray.push(this.formBuilder.control(managedConfigurationsStringValues[i]));
            } else if(['MULTISELECT'].includes(this.application.managedProperties[i].type)) {
              this.managedConfigurationsFormArray.push(this.formBuilder.control(managedConfigurationsArrayValues[i]));
            } else {
              this.managedConfigurationsFormArray.push(this.formBuilder.control(null));
            }
          }

          this.configurationForm.patchValue({
            packageName: this.application?.packageName,
            installType: this.applicationPolicy?.installType,
            autoUpdateMode: this.applicationPolicy?.autoUpdateMode,
            minimunVersionCode: this.applicationPolicy?.minimunVersionCode,
            defaultPermissionPolicy: this.applicationPolicy?.defaultPermissionPolicy,
            newPermissionKey: null,
            newPermissionPolicy: null
          });
        }
        
      });
    }
  }

  addPermission() {
    if(!this.applicationPolicy?.permissions) {
      this.applicationPolicy = {
        permissions: []
      }
    }
    this.applicationPolicy.permissions.push({'permission': this.configurationForm?.controls.newPermissionKey.value});
    this.applicationPermissionsFormArray.push(this.formBuilder.control(this.configurationForm?.controls.newPermissionPolicy.value));
    this.configurationForm.patchValue({
      newPermissionKey: null,
      newPermissionPolicy: null
    });
  }

  save() {
    let managedConfigurations = [];
    for (let i = 0; i < this.managedConfigurationsFormArray?.value?.length; i++) {      
      managedConfigurations.push({"key": this.application.managedProperties[i].key,"value": this.managedConfigurationsFormArray?.value[i]});
    }
    let permissions = [];
    for (let i = 0; i < this.applicationPermissionsFormArray?.value?.length; i++) {
      if(this.applicationPermissionsFormArray?.value[i]) {
        permissions.push({"permission": this.applicationPolicy.permissions[i].permission,"policy": this.applicationPermissionsFormArray?.value[i]});
      }
    }

    const application: IApplicationPolicy = {
      index: this.applicationPolicyIndex,
      packageName: this.application.packageName,
      installType: this.configurationForm?.controls.installType.value,
      autoUpdateMode: this.configurationForm?.controls.autoUpdateMode.value,
      minimunVersionCode: this.configurationForm?.controls.minimunVersionCode.value,
      defaultPermissionPolicy: this.configurationForm?.controls.defaultPermissionPolicy.value,
      managedConfiguration: managedConfigurations,
      permissions: permissions,
      idCompany: this.settings.getCompany().code
    };
    this.onSaved.emit(application);
    this.close();
  }

  close() {
    this.modal.close();
  }
}
