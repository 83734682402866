import { Component, Input, ViewEncapsulation, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 's1-input-switch',
  templateUrl: './s1-input-switch.component.html',
  styleUrls: ['./s1-input-switch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class S1InputSwitch implements OnInit {

  @Input() control: UntypedFormControl = new FormControl();
  @Input() label: string = '';
  @Input() grouped: boolean = false;
  @Input() readonly: boolean = false;
  @Input() styleClass: string = '';
  @Input() defaultValue?: boolean;
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
    if (this.defaultValue) {
      this.control.setValue(this.defaultValue);
      this.onToggle.emit(this.control.value);
    }
  }

  change(event: Event): void {
    this.onToggle.emit(event.target['checked']);
  }

}
