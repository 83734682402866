import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { SettingsService } from '@app/core/settings/settings.service';
import { S1ButtonType } from '@app/s1';
import { AeService, IApplication, IApplicationPolicy, IApplicationSearchParams, IManageConfiguration, IManageConfigurationMinimal } from '@app/shared/services/ae.service';

@Component({
  selector: 'profile-ae-form-configuration',
  templateUrl: './profile-ae-form-configuration.component.html',
  styleUrls: ['./profile-ae-form-configuration.component.scss']
})
export class ProfileAeFormConfigurationComponent implements OnInit {

  @Output() onSaved = new EventEmitter<any>();
  
  s1ButtonType = S1ButtonType;
  configurationForm: UntypedFormGroup;
  installTypeOptions = [];
  autoUpdateModeOptions = [];
  permissionPolicyOptions = [];
  
  application: IApplication;
  applicationPolicy: IApplicationPolicy;
  applicationPolicyIndex: number;

  packageNamesAlreadyUsedInPolicy = [];
  packageNameAlreadyUsed = false;
  packageNameNotFound = false;
  
  applicationPermissionsFormArray: UntypedFormArray;
  applicationPermissionsOptions = [];

  automaticPackageValue = false;
  showManagedConfigurations = false;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    private aeService: AeService,
    private settings: SettingsService
  ) {
    this.configurationForm = this.formBuilder.group({
      packageName: [null],
      installType: [null, Validators.required],
      autoUpdateMode: [null],
      minimunVersionCode: [null],
      defaultPermissionPolicy: [null],
      newPermissionKey: [null],
      newPermissionPolicy: [null],
      managedConfigurations: this.formBuilder.array([])
    });
    this.configurationForm.get('packageName').markAsTouched();
    this.configurationForm.get('installType').markAsTouched();
    this.installTypeOptions = [
      {"code": "INSTALL_TYPE_UNSPECIFIED", "label": "INSTALL_TYPE_UNSPECIFIED"},
      {"code:": "PREINSTALLED", "label": "PREINSTALLED"},
      {"code": "FORCE_INSTALLED", "label": "FORCE_INSTALLED"},
      {"code": "BLOCKED", "label": "BLOCKED"},
      {"code": "AVAILABLE", "label": "AVAILABLE"},
      {"code": "REQUIRED_FOR_SETUP", "label": "REQUIRED_FOR_SETUP"},
      {"code": "KIOSK", "label": "KIOSK"}
    ];
    this.autoUpdateModeOptions = [
      {"code": "AUTO_UPDATE_MODE_UNSPECIFIED", "label": "AUTO_UPDATE_MODE_UNSPECIFIED"},
      {"code": "AUTO_UPDATE_DEFAULT", "label": "AUTO_UPDATE_DEFAULT"},
      {"code": "AUTO_UPDATE_POSTPONED", "label": "AUTO_UPDATE_POSTPONED"},
      {"code": "AUTO_UPDATE_HIGH_PRIORITY", "label": "AUTO_UPDATE_HIGH_PRIORITY"}
    ];
    this.permissionPolicyOptions = [
      {"code": "PERMISSION_POLICY_UNSPECIFIED", "label": "PERMISSION_POLICY_UNSPECIFIED"},
      {"code": "PROMPT", "label": "PROMPT"},
      {"code": "GRANT", "label": "GRANT"},
      {"code": "DENY", "label": "DENY"}
    ];
  }

  ngOnInit(): void {
    this.configurationForm.controls.packageName.valueChanges.subscribe( value => {
        if(value) {
          this.packageNameAlreadyUsed = false;
          this.packageNameNotFound = false;
        }
      }
    );
  }

  get managedConfigurations(): UntypedFormArray {
    return <UntypedFormArray>this.configurationForm.get('managedConfigurations');
  }

  setControlError(control, error = null) {
    if(error) {
      control.markAsTouched();
      control.setErrors({'incorrect': true});
    } else {
      control.setErrors(null);
    }
  }
  
  inizializza(packageNamesAlreadyUsedInPolicy: string[], applicationPolicy: any, applicationPolicyIndex: number, packageName?: string) {
    this.packageNamesAlreadyUsedInPolicy = packageNamesAlreadyUsedInPolicy;
    this.applicationPolicy = applicationPolicy;
    this.applicationPolicyIndex = applicationPolicyIndex;
    this.application = null;
    this.packageNameAlreadyUsed = false;
    this.packageNameNotFound = false;

    //reset
    this.configurationForm.patchValue({
      packageName: packageName ? packageName : null,
      installType: null,
      autoUpdateMode: null,
      minimunVersionCode: null,
      defaultPermissionPolicy: null,
      newPermissionKey: null,
      newPermissionPolicy: null
    });

    this.setApplicationPolicyForm(applicationPolicy?.packageName);
    // If packageName is available at initialization the application info must be loaded automatically
    if (packageName) {
      this.loadApplication(packageName);
      this.automaticPackageValue = true;
    }
  }

  loadApplication(packageName: any) {
    this.packageNameNotFound = false;
    if(this.packageNamesAlreadyUsedInPolicy?.includes(packageName)) {
      this.setControlError(this.configurationForm.controls.packageName, true);
      this.packageNameAlreadyUsed = true;
      this.setApplicationPolicyForm(packageName);
    } else {
      this.packageNameAlreadyUsed = false;
      this.setApplicationPolicyForm(packageName);
    }
  }

  setApplicationPolicyForm(packageName: string) {
    this.applicationPermissionsFormArray =  this.formBuilder.array([]);

    if(packageName && !this.packageNameAlreadyUsed && !this.packageNameNotFound) {
      //nuova configurazione, applicationPolicy null, filtro su packageNameAlreadyUsedInPolicy
      //modifica configurazione, packageName non modificabile
      //get struttura da packageName
      let parameters: IApplicationSearchParams = {
        packageName: packageName,
        idCompany: this.settings.getCompany().code,
      }
      this.aeService.getApplication(parameters).subscribe(application => {
        this.application = application;

        if(!this.application) {
          this.setControlError(this.configurationForm.controls.packageName, true);
          this.packageNameNotFound = true;
        } else {
          this.application.packageName = packageName;
          this.packageNameNotFound = false;
          
          //init form permissions: dal json mi prendo il nodo permissions e lo carico nella select.
          this.applicationPermissionsOptions = this.application.permissions.map(applicationPermission => {
            return { code: applicationPermission.permissionId, label: applicationPermission.permissionId.split('.').pop() };
          });
          
          //carico quelli precedentemente aggiunti nell'array
          for (let i = 0; i < this.applicationPolicy?.permissions.length; i++) {
            this.applicationPermissionsFormArray.push(this.formBuilder.control(this.applicationPolicy?.permissions[i].policy));
          }

          this.configurationForm.patchValue({
            packageName: this.application?.packageName,
            installType: this.applicationPolicy?.installType,
            autoUpdateMode: this.applicationPolicy?.autoUpdateMode,
            minimunVersionCode: this.applicationPolicy?.minimunVersionCode,
            defaultPermissionPolicy: this.applicationPolicy?.defaultPermissionPolicy,
            newPermissionKey: null,
            newPermissionPolicy: null
          });
        }
        
      });
    }
  }

  cloneArrayBundle(idx: number) {
    let bundle_array = {...this.application.managedProperties[idx].nestedProperties.at(-1), clone: true};
    this.application.managedProperties[idx].nestedProperties.push(bundle_array);
  }

  addPermission() {
    if(!this.applicationPolicy?.permissions) {
      this.applicationPolicy = {
        permissions: []
      }
    }
    this.applicationPolicy.permissions.push({'permission': this.configurationForm?.controls.newPermissionKey.value});
    this.applicationPermissionsFormArray.push(this.formBuilder.control(this.configurationForm?.controls.newPermissionPolicy.value));
    this.configurationForm.patchValue({
      newPermissionKey: null,
      newPermissionPolicy: null
    });
  }

  save(): void {
    let permissions = [];
    for (let i = 0; i < this.applicationPermissionsFormArray?.value?.length; i++) {
      if(this.applicationPermissionsFormArray?.value[i]) {
        permissions.push({
          "permission": this.applicationPolicy.permissions[i].permission,
          "policy": this.applicationPermissionsFormArray?.value[i]
        });
      }
    }

    const application: IApplicationPolicy = {
      index: this.applicationPolicyIndex,
      packageName: this.application.packageName,
      installType: this.configurationForm?.controls.installType.value,
      autoUpdateMode: this.configurationForm?.controls.autoUpdateMode.value,
      minimunVersionCode: this.configurationForm?.controls.minimunVersionCode.value,
      defaultPermissionPolicy: this.configurationForm?.controls.defaultPermissionPolicy.value,
      managedConfiguration: this.configureManagedConfigurations(),
      permissions: permissions,
      idCompany: this.settings.getCompany().code
    };
    console.warn('>>>>>>SAVE APPLICATION: ', application);
    this.onSaved.emit(application);
  }

  onShowManagedConfigurations(): void {
    this.showManagedConfigurations = !this.showManagedConfigurations;
  }

  onManagedConfigurationRemove(configIdx: number, managedConfigIdx: number): void {
    this.application.managedProperties[managedConfigIdx].nestedProperties.splice(configIdx, 1);
  }

  onManagedConfigurationExpand(propertiesNode: any): void {
    propertiesNode.open = !propertiesNode.open ? true : false;
  }

  onManagedConfigurationChange(propertiesNode: any, configValue: any): void {
    propertiesNode.value = configValue.code ? configValue.code : propertiesNode.defaultValue;
  }

  /** Method that creates the payload for the application managed configurations */
  private configureManagedConfigurations(): IManageConfigurationMinimal[] {
    const managedConfigurations = [...this.application.managedProperties];
    return managedConfigurations.map(manageConfiguration => this.formatManagedConfiguration(manageConfiguration));
  }

  /**
   * Method that formats the application managed configuration as required by the API
   * 
   * For each nestedProperties entry the method is called recursively, in order to correctly format each property
   */
  private formatManagedConfiguration(manageConfiguration: IManageConfiguration): IManageConfigurationMinimal {
    return { 
      key: manageConfiguration.key,
      type: manageConfiguration.type,
      value: manageConfiguration.value,
      ...(manageConfiguration.nestedProperties) && { nestedProperties: manageConfiguration.nestedProperties.map(nestedConfiguration => this.formatManagedConfiguration(nestedConfiguration)) }
    };
  }

}